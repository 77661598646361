// @ts-check
/* eslint-disable jsx-a11y/heading-has-content */
import { graphql } from "gatsby"
import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { TypographyMarkdown } from "../components/typography"
import Card, { CardTitle } from "../components/card"
import { HoverButton } from "../components/button"
import Breadcrumbs, { BreadcrumbCard } from "../components/breadcrumbs"
import colors from "../styles/colors"
import { slugify } from "../../shared/utils"
import Link from "../components/link"

const Wrapper = styled(TypographyMarkdown)`
  max-width: 660px;
  margin-bottom: 30px;
`
const ApplyLink = HoverButton.withComponent("a")

const DataPrivacyCopy = styled.em`
  display: block;
  margin-top: 80px;
  font-size: 13px;
`

/**
 * Removes `<h1>` tags and replaces it by `<h2>` for an html string
 * (for SEO and styling reasons)
 * @param {string} htmlString
 */
const removeH1s = htmlString =>
  htmlString.replace("<h1>", "<h2>").replace("</h1>", "</h2>")

const CareersTemplate = ({ data: { offer } }) => (
  <Layout bg="white" newsletter={null}>
    <SEO title={offer.title} />

    <BreadcrumbCard>
      <Breadcrumbs
        items={[
          { to: "/careers/", content: "Open positions" },
          { to: `/careers/${slugify(offer.title)}/`, content: offer.title },
        ]}
      />
    </BreadcrumbCard>

    <Card variant="white">
      <CardTitle variant="h1" as="h1" style={{ color: colors.primary }}>
        {offer.title}
      </CardTitle>
      <Wrapper>
        <span
          dangerouslySetInnerHTML={{
            __html: removeH1s(offer.description),
          }}
        />
        <div style={{ height: 40 }} />
        {offer.lists.map((list, index) => (
          <React.Fragment key={index}>
            <h5>{list.text}</h5>
            <ul dangerouslySetInnerHTML={{ __html: list.content }} />
            <div style={{ height: 40 }} />
          </React.Fragment>
        ))}
        <span
          dangerouslySetInnerHTML={{
            __html: removeH1s(offer.additional),
          }}
        />

        <DataPrivacyCopy>
          By submitting the application I confirm to have read and accepted the{" "}
          <Link to="/privacy-policy-candidates/" forceLanguage="en">
            privacy policy
          </Link>{" "}
          governing the job application process.
        </DataPrivacyCopy>
      </Wrapper>

      <ApplyLink href={offer.applyUrl}>Apply now</ApplyLink>
    </Card>
  </Layout>
)

export default CareersTemplate

export const query = graphql`
  query careers($id: String!) {
    offer: lever(id: { eq: $id }) {
      title: text
      description
      lists {
        text
        content
      }
      additional
      applyUrl
    }
  }
`
