// @ts-check
import React from "react"
import styled from "@emotion/styled"

import { typographyComponents } from "./typography"
import Link from "./link"
import colors from "../styles/colors"
import { CardLateralWrapper } from "./card"

const Wrapper = styled(typographyComponents.label)`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow-x: auto;
`.withComponent("ul")

const Item = styled.li`
  &:not(:last-child)::after {
    margin: 0 8px;
    content: ">";
    opacity: 0.5;
  }
`
const BackLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const ActiveBackLink = styled(Link)`
  color: ${colors.primary};
  text-decoration: none;
`

/**
 * @typedef {object} BreadcrumbItem
 * @property {string} to
 * @property {React.ReactNode} content
 */

/**
 * @param {object} props
 * @param {BreadcrumbItem[]} props.items
 */
const Breadcrumbs = ({ items }) => {
  // Scroll breadcrumbs to the end of the list (for mobile viewports)
  const wrapperRef = React.useRef(/** @type {HTMLUListElement | null} */ (null))
  React.useEffect(() => {
    if (!wrapperRef.current) return
    wrapperRef.current.scrollLeft = 10000
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      {items.map((item, index) => (
        <Item key={index}>
          {index < items.length - 1 ? (
            <BackLink to={item.to}>{item.content}</BackLink>
          ) : (
            <ActiveBackLink to={item.to}>{item.content}</ActiveBackLink>
          )}
        </Item>
      ))}
    </Wrapper>
  )
}

export default Breadcrumbs

export const BreadcrumbCard = styled(CardLateralWrapper)`
  margin-bottom: 20px;
`
